import React from "react"
import { Box } from "@mui/material"
import { Link as GatsbyLink } from "gatsby"

const Link = ({ href = "#", children, sx }) => {
  if (href.includes("http"))
    return (
      <Box
        sx={{
          a: { textDecoration: "none !important" },
          ...sx,
        }}
      >
        <a href={href}>{children}</a>
      </Box>
    )
  else
    return (
      <Box
        sx={{
          a: { textDecoration: "none !important" },
          ...sx,
        }}
      >
        <GatsbyLink to={href}>{children}</GatsbyLink>
      </Box>
    )
}

export default Link
