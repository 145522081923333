import React from "react"
import styled from "@emotion/styled"
import { Drawer, Hidden } from "@mui/material"

import NavItem from "./NavItem"

const MobileDrawer = styled(Drawer)`
  ${({ theme }) => `

  .MuiDrawer-modal {
    margin-top: 180px;
  }
  .MuiDrawer-paper {
      background-color: ${theme.palette.background.default};
      transform: none !important;
      justify-content: flex-start;
      padding: 16px;
      margin-top: ${theme.topBarHeight + 2}px;
      ${theme.breakpoints.down("menu")} {
        margin-top: 1px;
      }    
  }
  `}
`

const NavBar = ({ darkNavbar, openMobile, onMobileClose }) => {
  const strapiNavigacija = [
    { id: 3, naslov: "Products", href: "/?section=products" },
    { id: 4, naslov: "Clients", href: "/?section=clients" },
    { id: 5, naslov: "News", href: "/news" },
    {
      id: 6,
      naslov: "Support",
      href: "http://zinp.infostudio.ba/ts/faces/login.jsp",
    },
    { id: 7, naslov: "Career", href: "/careers" },
    { id: 8, naslov: "About us", href: "/about-us" },
  ]

  const content = strapiNavigacija.map(({ naslov, href }) => (
    <NavItem key={naslov} title={naslov} href={href} darkNavbar={darkNavbar} />
  ))

  return (
    <>
      <Hidden menuUp implementation="css">
        <MobileDrawer
          anchor="top"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          elevation={1}
          ModalProps={{ BackdropProps: { invisible: true } }}
          sx={{ "*": { color: "black !important" } }}
        >
          {content}
          <NavItem title="Contact us" href="/contact" darkNavbar={darkNavbar} />
        </MobileDrawer>
      </Hidden>
      <Hidden menuDown implementation="css">
        {content}
      </Hidden>
    </>
  )
}

export default NavBar
