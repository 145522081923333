import React from "react"
import { Helmet } from "react-helmet"
// import { Box, IconButton } from "@mui/material"
// import { AiOutlineVerticalAlignTop } from "react-icons/ai"

import Header from "./Header"
import Footer from "./Footer"
import { Root, Wrapper } from "./style"
import FullWidthStripe from "../FullWidthStripe"

const Layout = ({ darkNavbar, path, isContactPage, children, ...rest }) => {
  const [isMobileNavOpen, setMobileNavOpen] = React.useState(false)
  const [navCanBeDark, setnavCanBeDark] = React.useState(darkNavbar)
  const [showNav, setShowNav] = React.useState(true)
  const ref = React.useRef(null)

  React.useEffect(() => {
    setMobileNavOpen(false)
  }, [path])
  React.useEffect(() => {
    const threshold = 100
    let lastScrollY = document.body.scrollTop
    let ticking = false

    const updateScrollDir = () => {
      const scrollY = document.body.scrollTop

      if (Math.abs(scrollY - lastScrollY) < threshold) {
        ticking = false
        return
      }
      setShowNav(scrollY < 200 || scrollY < lastScrollY)
      setnavCanBeDark(scrollY < window.innerHeight + 50)
      lastScrollY = scrollY > 0 ? scrollY : 0
      ticking = false
    }

    const onScroll = () => {
      if (!ticking) {
        window.requestAnimationFrame(updateScrollDir)
        ticking = true
      }
    }
    document.body.addEventListener("scroll", onScroll)

    return () => document.body.removeEventListener("scroll", onscroll)
  }, [showNav])

  React.useEffect(() => {
    ref.current.scrollIntoView()
  }, [])

  return (
    <Root ref={ref} {...rest}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>infoStudio</title>
      </Helmet>
      {showNav && (
        <FullWidthStripe
          height={{ xs: 82, lg: 92 }}
          zIndex={1000}
          bgcolor={darkNavbar && navCanBeDark ? "#000" : "#fff"}
          position="relative"
        >
          <Header
            darkNavbar={darkNavbar && navCanBeDark}
            setMobileNavOpen={setMobileNavOpen}
            openMobile={isMobileNavOpen}
          />
        </FullWidthStripe>
      )}
      <Wrapper>{children}</Wrapper>
      <Footer isContactPage={isContactPage} />
    </Root>
  )
}

export default Layout
