import React from "react"

import { Box, Typography, Divider, IconButton } from "@mui/material"
import { FaFacebookF, FaLinkedinIn, FaInstagram } from "react-icons/fa"

import MyLink from "../Link"
import MailIcon from "../../images/mailicon.svg"
import CareerIcon from "../../images/career.svg"
// import { StaticImage } from "gatsby-plugin-image"

import darkLogo from "../../images/darkLogo.svg"
import isoStandards from "../../images/ISO-standard.png"

const footerLinks = [
  {
    header: "Navigate",
    items: [
      { text: "Home", href: "/" },
      { text: "Products", href: "/?section=products" },
      { text: "Industries", href: "/?section=industries" },
      { text: "Blogs", href: "/404" },
    ],
  },
  {
    header: "Featured Products",
    items: [
      { text: "smartWMS", href: "https://www.smartwms.ai/" },
      { text: "smartTMS", href: "https://www.smarttms.ai/" },
      { text: "Human Capital Management", href: "https://hcmnow.netlify.app/" },
      { text: "Sales Forecasting", href: "/404" },
    ],
  },
  {
    header: "About",
    items: [
      { text: "About us", href: "/about-us" },
      { text: "Contact", href: "/contact" },
      { text: "Support", href: "http://zinp.infostudio.ba/ts/faces/login.jsp" },
      { text: "Join Us", href: "/careers" },
    ],
  },
  {
    header: "Follow Us",
    items: [
      {
        text: "Linkedin",
        href: "https://www.linkedin.com/company/info-studio-d-o-o-sarajevo/mycompany/",
      },
      { text: "Instagram", href: "https://www.instagram.com/infostudio.doo/" },
      { text: "Facebook", href: "https://www.facebook.com/InfoStudiodoo" },
    ],
  },
]

const Mail = () => (
  <MyLink href="/contact" sx={{ flex: 1 }}>
    <Box
      bgcolor="black"
      boxShadow="4px 4px 20px rgba(0, 33, 149, 0.1)"
      borderRadius={2}
      display="flex"
      p={3}
      pt={4}
      gap={2}
      justifyContent="center"
    >
      <img src={MailIcon} width={44} />
      <Box display="flex" flexDirection="column">
        <Typography variant="body1" color="white">
          Send us an email
        </Typography>
        <Typography variant="body3" color="white">
          info@infostudio.ba
        </Typography>
      </Box>
    </Box>
  </MyLink>
)

const CareerMail = () => (
  <MyLink href="/careers" sx={{ flex: 1 }}>
    <Box
      boxShadow="4px 4px 20px rgba(0, 33, 149, 0.1)"
      borderRadius={2}
      display="flex"
      px={4}
      py={3.5}
      gap={2}
      justifyContent="center"
    >
      <img src={CareerIcon} width={44} />
      <Box display="flex" flexDirection="column">
        <Typography variant="body1" color="black">
          Join our team
        </Typography>
        <Typography variant="body3" color="text.secondary">
          careers@infostudio.ba
        </Typography>
      </Box>
    </Box>
  </MyLink>
)

const Footer = ({}) => {
  return (
    <Box display="flex" flexDirection="column" gap={5}>
      <Box
        display="flex"
        justifyContent={{ xs: "center", lg: "space-between" }}
        alignItems="center"
        gap={5}
        flexWrap={{ xs: "wrap", lg: "nowrap" }}
      >
        <Box
          display="flex"
          flexDirection="row"
          flexWrap="wrap"
          textAlign={{ xs: "center", lg: "left" }}
          order={{ xs: 2, md: 1 }}
          gap={4}
        >
          <MyLink href="/" sx={{ img: { width: { xs: 180, lg: 220 } } }}>
            <img src={darkLogo} alt="Dark Info Studio Logo" />
          </MyLink>
          <Box
            sx={{
              img: {
                width: { xs: 0.7, sm: 220, lg: 220 },
                mt: { xs: -2, sm: -4 },
              },
            }}
          >
            <img src={isoStandards} />
          </Box>
        </Box>

        <Box display="flex" flexWrap="wrap" gap={4} order={{ xs: 2, md: 1 }}>
          <Mail />
          <CareerMail />
        </Box>
      </Box>
      <Divider />
      <Box
        display="flex"
        justifyContent="space-between"
        flexWrap="wrap"
        mt={2}
        gap={5}
      >
        {footerLinks.map(({ header, items }) => (
          <Box
            key={header}
            display="flex"
            flexDirection="column"
            gap={2}
            flex={{ xs: "1 40%", md: "1" }}
            maxWidth={{ xs: 230 }}
          >
            {" "}
            <Typography variant="body1" sx={{ mb: 1 }} fontWeight={600}>
              {header}
            </Typography>
            {items.map(({ text, href }) => (
              <Box key={text} sx={{ a: { textDecoration: "none !important" } }}>
                <MyLink key={text} href={href}>
                  <Typography variant="body2">{text}</Typography>
                </MyLink>
              </Box>
            ))}
          </Box>
        ))}
      </Box>
      <Box
        display="flex"
        justifyContent="space-between"
        mt={4}
        alignItems="center"
      >
        <Typography variant="body3" color="#9D9D9D">
          © 2022 Info Studio
        </Typography>
        <Box display="flex" gap={1}>
          <MyLink href="https://www.facebook.com/InfoStudiodoo">
            <IconButton
              aria-label="Facebook link"
              sx={{ border: "0.3px solid #9CC2FE" }}
            >
              <FaFacebookF color="#2E6ACA" size={18} />
            </IconButton>
          </MyLink>
          <MyLink href="https://www.linkedin.com/company/info-studio-d-o-o-sarajevo/mycompany/">
            <IconButton
              aria-label="LinkedIn link"
              sx={{ border: "0.3px solid #9CC2FE" }}
            >
              <FaLinkedinIn color="#2E6ACA" size={18} />
            </IconButton>
          </MyLink>
          <MyLink href="https://www.instagram.com/infostudio.doo/">
            <IconButton
              aria-label="Instagram link"
              sx={{ border: "0.3px solid #9CC2FE" }}
            >
              <FaInstagram color="#2E6ACA" size={18} />
            </IconButton>
          </MyLink>
        </Box>
      </Box>
    </Box>
  )
}

export default Footer
