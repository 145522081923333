import React, { useState, useRef } from "react"
import styled from "@emotion/styled"
import { Button as MuiButton, Menu, MenuItem } from "@mui/material"
import { FiChevronDown } from "react-icons/fi"

import Link from "../Link"

const NavItem = ({ title, href, darkNavbar, items }) => {
  if (items) return <MenuNavItem title={title} items={items} />
  return (
    <Link href={href} sx={{ display: "inline-block" }}>
      <Button
        aria-label={title}
        disableRipple
        disableTouchRipple
        disableElevation
        size="small"
        sx={({ palette }) => ({
          fontSize: "1.25rem",
          p: "16px !important",
          color: darkNavbar
            ? "white !important"
            : `${palette.text.secondary} !important`,
        })}
      >
        {title}
      </Button>
    </Link>
  )
}

const MenuNavItem = ({ title, items }) => {
  const ref = useRef(null)
  const [isOpen, setOpen] = useState(false)

  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <>
      <Button
        aria-label={title}
        size="medium"
        onClick={handleOpen}
        ref={ref}
        endIcon={<FiChevronDown />}
        disableRipple
        disableElevation
      >
        {title}
      </Button>
      <Menu
        elevation={0}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        keepMounted
        getContentAnchorEl={null}
        anchorEl={ref.current}
        open={isOpen}
        disableScrollLock
        sx={{
          "& .MuiMenu-list": {
            backgroundColor: "background.block",
          },
        }}
      >
        {items.map(({ title, href }) => (
          <MenuItem key={title} disableGutters>
            <NavItem title={title} href={href} />
          </MenuItem>
        ))}
      </Menu>
    </>
  )
}

const Button = styled(MuiButton)`
  ${({ theme }) => `
    color: black;

    font-weight: 600; 
    ${theme.breakpoints.down("md")} {
      justify-content: flex-start;
    }
    
    &.active {
      text-decoration: underline;
    }

    &:hover {
      background-color: transparent;
      color: ${theme.palette.primary.main} !important;
    }
  `}
`

export default NavItem
