import React from "react"

import {
  Toolbar as MuiToolbar,
  AppBar as MuiAppBar,
  IconButton,
  Button,
  Box,
  Hidden,
  styled,
} from "@mui/material"
import { Link } from "gatsby"
import { FiMenu as MenuIcon, FiX as XIcon } from "react-icons/fi"

import MyLink from "./../Link"
import NavBar from "./NavBar"
import darkLogo from "../../images/darkLogo.svg"
import lightLogo from "../../images/lightLogo.svg"

const Header = ({
  darkNavbar,
  openMobile,
  setMobileNavOpen,
  sx = {},
  ...rest
}) => {
  return (
    <AppBar
      sx={{
        bgcolor: "inherit",
        px: { xs: 2, sm: 5, lg: 10, xl: "calc(10% - 8px)" },
        pb: 2,
        pt: { xs: 3, md: 2 },
        maxWidth: { lg: "calc(100vw - 16px)" },
        left: { lg: -16 },

        backgroundColor: darkNavbar ? "rgba(0, 0, 0)" : "rgba(255, 255, 255)",

        ...sx,
      }}
      {...rest}
    >
      <Toolbar disableGutters sx={{ minHeight: "auto !important" }}>
        <Box height={1}>
          <Link to="/" sx={{ textDecoration: "none", color: "inherit" }}>
            {darkNavbar && (
              <img
                src={lightLogo}
                alt="Light Info Studio Logo"
                width={136}
                height={50}
              />
            )}
            {!darkNavbar && (
              <img
                src={darkLogo}
                alt="Dark Info Studio Logo"
                width={136}
                height={50}
              />
            )}
          </Link>
        </Box>

        <Hidden menuUp implementation="css">
          <IconButton onClick={() => setMobileNavOpen(true)} size="large">
            {openMobile ? (
              <XIcon />
            ) : (
              <MenuIcon color={darkNavbar ? "white" : "black"} />
            )}
          </IconButton>
        </Hidden>
        <Hidden menuDown implementation="css">
          <Box
            display="flex"
            justifyContent="space-between"
            alignItems="center"
            gap={2}
          >
            <NavBar
              darkNavbar={darkNavbar}
              onMobileClose={() => setMobileNavOpen(false)}
              openMobile={openMobile}
            />

            <MyLink href="/contact">
              <Button
                aria-label="Contact us"
                variant="contained"
                size="small"
                color="primary"
              >
                Contact us
              </Button>
            </MyLink>
          </Box>
        </Hidden>
      </Toolbar>
    </AppBar>
  )
}

const AppBar = styled(MuiAppBar)`
  ${({ theme }) => `
  background-color: ${theme.palette.background.default};
  box-shadow: none;
  `}
`

const Toolbar = styled(MuiToolbar)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
`

export default Header
