import React from "react"
import { Box } from "@mui/material"

const FullWidthStripe = ({ children, sx = {}, ...rest }) => (
  <Box
    {...rest}
    width="100vw"
    ml={{
      xs: `calc((-100vw + 100%) / 2)`,
    }}
    sx={{ "& > *": { mx: "auto" }, ...sx }}
  >
    {children}
  </Box>
)

export default FullWidthStripe
