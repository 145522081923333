import styled from "@emotion/styled"

export const Root = styled.div`
  background-color: white;
  display: flex;
  margin: auto;
  flex-direction: column;
  height: 100%;

  /* img {
    border-radius: 10px;
  } */

  .spinner {
    animation: spin infinite 2s linear;
  }

  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }

  ${({ theme }) => `
    // max-width: 1350px;
    max-width: 80vw;  
    margin-bottom: ${theme.spacing(10)};
    // padding-top: ${theme.spacing(5)};

    ${theme.breakpoints.down("xl")} {
      margin-left: ${theme.spacing(10)};
      margin-right: ${theme.spacing(10)};
      max-width: 1350px;
    }

    ${theme.breakpoints.down("lg")} {
      margin-left: ${theme.spacing(5)};
      margin-right: ${theme.spacing(5)};
    }

    ${theme.breakpoints.down("sm")} {
      margin-left: ${theme.spacing(2)};
      margin-right: ${theme.spacing(2)};
      // padding-top: ${theme.spacing(3)};
    }
  `}
`

export const Wrapper = styled.div`
  display: flex;
  flex: 1 1 auto;
  flex-direction: column;
  position: relative;

  ${({ theme }) => `
    margin-bottom: ${theme.spacing(10)};

    row-gap: ${theme.spacing(15)};

    ${theme.breakpoints.down("lg")} {
      row-gap: ${theme.spacing(12.5)};
      // margin-bottom: ${theme.spacing(15)};
    }

    ${theme.breakpoints.down("sm")} {
      row-gap: ${theme.spacing(8)};
      // margin-bottom: ${theme.spacing(15)};
    }
  `}
`
